body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-image: url("./background.jpg");     
  background-size: cover;

}

.statement-generator {
  width: 1100px;
  margin: 0 auto;
  padding-top: 10%;
}

#generate-button {
  border: none;
  border-radius: 75%;
  background-color: #99011e;
  width: 75%;
  height: 30px;
  cursor: pointer;
  font-weight: bolder;
  color: white;
  margin-left: 15%;
}

#statement {
  width: 1000px;
  height: 50px;
  align-content: center;
  margin-top: 5%;
}